import React from 'react';
import {
  ContinuedTextListModule,
  FeaturedArticles,
  Hero,
  Layout,
  Module,
  SingleQuote,
  StatisticStructuredBox,
  Structured
} from '../../redesign';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';

import heroContent from '../../redesign/content/burnout/hero.json';
import singleStatStructuredContent from '../../redesign/content/burnout/single-stat-structure.json';
import symptomsListContent from '../../redesign/content/burnout/symptoms-list.json';
import structuredContent from '../../redesign/content/burnout/structured-understand';
import quoteCarouselContent from '../../redesign/content/burnout/quote-carousel.json';
import blogPostContent from '../../redesign/content/burnout/blog-post.json';
import { default as SEO } from '../../components/SEO';

export default function Burnout() {
  return (
    <Layout footerContentKey="burnout">
      <SEO
        pageTitle="Burnout Recovery Treatment | Severe Burnout Care | Parsley Health"
        pageDescription="Burnout has been on the rise, and is driven by chronic stress and increasing demands of life in these times. Get holistic, whole-body treatment for severe burnout from Parsley Health."
      />
      <Hero {...heroContent} />
      <Module>
        <StatisticStructuredBox {...singleStatStructuredContent} />
      </Module>
      <Module>
        <ContinuedTextListModule {...symptomsListContent} />
      </Module>
      <Module>
        <Structured {...structuredContent} />
      </Module>
      <Module>
        <SingleQuote {...quoteCarouselContent} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf
          eyebrow="How to reverse burnout"
          cta={{ label: 'Meet Our Providers', destination: '/providers/' }}
          ctaTrackingLabel="Meet Our Providers button on the Burnout page - Stress-free online holistic care module"
        />
      </Module>
      <Module>
        <FeaturedArticles {...blogPostContent} />
      </Module>
    </Layout>
  );
}
