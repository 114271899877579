import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: 'Reverse burnout and rebuild your health.',
  items: [
    {
      id: 'hormonal-screening',
      heading: 'We dive deep into your health history.',
      description:
        'We spend the time it takes to learn all about your medical history, your lifestyle, and your major stressors—which often include work, family, and other health conditions.',
      illustration: <IllustrativeIcon name="personal" />
    },
    {
      id: 'whole-body-approach',
      heading: "Advanced diagnostics many doctors don't do.",
      description:
        'First we rule out obvious culprits like nutrient deficiencies and hormonal imbalances. Then we assess your adrenal health by checking your cortisol levels over the course of the day and look for inflammatory markers that may signal burnout.',
      illustration: <IllustrativeIcon name="testing" />
    },
    {
      id: 'personalized-ongoing-support',
      heading: 'Rebalance your life and start healing.',
      description:
        'Re-establish cortisol balance by supporting your adrenal health. Your Care Team will make you a personalized plan for stress management, supplements and herbs, better sleep hygiene, and optimized nutrition, supporting you every step of the way.',
      illustration: <IllustrativeIcon name="teamCollab" />
    }
  ]
};

export default Data;
